
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Pageniation as Table } from "@/utils/decorator";
import { getImageSize, emptyToLine, getUuid } from "@/utils/common";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const app = namespace("app");
const base = namespace("base");
const common_base = namespace("common_base");
const learning = namespace("learning");
@Component({
  components: { FileUpload, Editor }
})
@Table("loadListData")
export default class LearningEdit extends Vue {
  @base.Action getDictionaryList;
  @common_base.Action getUploadToken;
  @app.Mutation setPBPX;
  @app.State btnLoading;
  // 学习
  @learning.Action queryLearnClassList;
  @learning.Action createLearnClass;
  @learning.Action queryExamPaperList;
  @learning.Action getDefaultSerialNumber;
  // @learning.Action repeatTitle;
  @learning.Action createLearnContent;
  @learning.Action updateLearnContent;
  @learning.Action getLearnContentSettingDetail; //获取学习内容设置明细
  @learning.Action getLearnContentTextDetail; //获取学习内容文本明细

  emptyToLine = emptyToLine;
  contentForm: any = {
    learnContentTitle: "", //标题
    learnContentType: "", //分类
    scopeType: "storeType", //发布范围类型
    scopeCodeList: [], //发布范围
    signature: "", //署名
    bannerUrl: "", //小图
    connectedExternal: "false", //链接至第三方
    admissionExamination: "false", //允许报名考试
    examinationCode: "", //关联考试
    signUpDeadlineTime: "", //报名截止日期
    externalUrl: "", //地址
    learnContentAbstract: "", //摘要
    learnContentText: "", //学习内容
    annexList: [], //添加附件
    isRelease: "", //是否发布，false-否，true-是; 默认 false
    serialNumber: "" // 序号
  };
  storeType: any[] = [];
  staffLevel: any[] = [];
  storeTypeList: any[] = [];
  staffLevelList: any[] = [];
  releteList: any[] = [];
  get getPickerOptions() {
    let pickerOptions = {
      disabledDate: time => {
        return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 || time.getTime() > this.checkExaminationTime;
      }
    };
    return pickerOptions;
  }

  contentRangeList: any[] = [];
  contentClassList: any[] = [];
  contentClassListCopy: any[] = [];
  dialogFormVisible: boolean = false;
  dayjs = dayjs;
  contentFormRules: any = {
    learnContentTitle: [
      { required: true, message: t("v210831.please-enter-the-study-title"), trigger: "blur" },
      { max: 50, message: t("v210831.up-to-50-max"), trigger: "blur" }
    ],
    learnContentType: [{ required: true, message: t("v210831.please-select-a-study-class"), trigger: "change" }],
    scopeCodeList: {
      type: "array",
      required: true,
      message: t("v210831.please-select-the"),
      trigger: "submit"
    },
    signature: [
      { required: true, message: t("v210831.please-enter-a-name"), trigger: "blur" },
      { max: 50, message: t("v210831.up-to-50-max"), trigger: "blur" }
    ],
    bannerUrl: { required: true, validator: this.checkFrontImage },
    learnContentAbstract: [
      { required: true, message: t("v210831.please-enter-a-digest"), trigger: "blur" },
      { max: 100, message: t("v210831.up-to-100-max"), trigger: "blur" }
    ],
    examinationCode: [{ required: true, message: t("v210831.please-select-an"), trigger: "change" }],
    signUpDeadlineTime: [{ required: true, message: t("v210831.please-enter-the-apply-end-time"), trigger: "blur" }],
    externalUrl: [{ required: true, message: t("v210831.please-enter-the-address"), trigger: "blur" }],
    learnContentText: [{ required: true, message: t("v210831.please-enter-the-content"), trigger: "blur" }],
    serialNumber: [
      { required: true, message: t("v210831.please-enter-serial"), trigger: "blur" },
      { type: "number", message: t("v210831.must-be-an-integer"), trigger: "blur" }
    ]
  };
  checkFrontImage(rule, value, callback) {
    if (this.contentForm.bannerType === "default") {
      callback();
    } else {
      if (!value) {
        callback(new Error(t("v210831.please-upload-pictures")));
      } else {
        callback();
      }
    }
  }
  get breadData() {
    return [
      { name: t("v210831.learning-content-management"), path: "/learning/learning-list" },
      {
        name: this.isAdd ? t("v210831.new-learning-content") : t("v210831.edit-learning-content")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "learningAdd";
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  get id() {
    return this.$route.params.id || this.$route.query.id;
  }
  defaultBannerList: any = [];
  checkExaminationTime: any = "";
  changeExamination(code) {
    let item = this.releteList.find(v => {
      return v.examPaperTempCode === code;
    });
    this.checkExaminationTime = new Date(item.examBeginTime).getTime();
    this.contentForm.signUpDeadlineTime = dayjs(new Date(this.checkExaminationTime - 60000)).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }
  async init() {
    if (!this.isAdd || this.$route.query.id) {
      this.getLearnContentSettingDetail({ learnContentCode: this.id }).then(data => {
        this.contentForm = data.data;
        // this.contentForm.serialNumber = Number(data.data.serialNumber);
        this.getDefaultSerialNumber({ learnContentType: data.data.learnContentType }).then(res => {
          this.contentForm.serialNumber = res.data;
        });
        if (this.contentForm.signUpDeadlineTime) {
          this.contentForm.signUpDeadlineTime = dayjs(this.contentForm.signUpDeadlineTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        this.defaultBannerList = [{ url: this.contentForm.bannerUrl }];
        this.classSearchValue = this.contentForm.learnContentTypeDesc; //分类名称
        if (this.contentForm.scopeType === "storeType") {
          this.storeType = this.contentForm.scopeCodeList;
        } else {
          this.staffLevel = this.contentForm.scopeCodeList;
        }
        this.contentForm.annexList = this.contentForm.annexList.map(v => {
          return { ...v, name: v.annexName, url: v.annexUrl };
        });
        if (this.contentForm.connectedExternal === "true") {
          this.contentForm.annexList = [];
        }
        // this.changeFormRules();
        this.changeFormData();
      });
      // this.getLearnContentTextDetail({ learnContentCode: this.id }).then(data => {
      //   this.contentForm = data.data;
      //   this.classSearchValue = this.contentForm.learnContentTypeDesc; //分类名称
      // });
    }
    // 关联考试列表
    this.queryExamPaperList({ pageNum: 1, pageSize: 999, examPaperTempStatus: "5" }).then(data => {
      this.releteList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.examPaperTempName;
            item.value = item.examPaperTempCode;
            return item;
          })
        : [];
    });
    this.getContentClassList();
    // 发布范围字典List
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    this.getDictionaryList("staff_level").then(data => {
      this.staffLevelList = data;
    });
    this.changeFormRules();
  }
  mounted() {}
  // 动态表单验证
  changeFormRulesAdress() {
    this.contentForm.externalUrl = undefined;
    this.contentForm.learnContentText = undefined;
    this.contentFormRules["externalUrl"] = null;
    this.contentFormRules["learnContentText"] = null;
    if (this.contentForm.connectedExternal === "true") {
      this.contentFormRules["externalUrl"] = [
        { required: true, message: t("v210831.please-enter-the-adress"), trigger: "blur" }
      ];
    } else {
      this.contentFormRules["learnContentText"] = [
        { required: true, message: t("v210831.please-enter-the-content"), trigger: "blur" }
      ];
    }
  }
  changeFormRules() {
    this.contentForm.examinationCode = undefined;
    this.contentForm.signUpDeadlineTime = undefined;
    this.contentFormRules["examinationCode"] = null;
    this.contentFormRules["signUpDeadlineTime"] = null;
    this.contentFormRules["externalUrl"] = null;
    this.contentFormRules["learnContentText"] = null;
    if (this.contentForm.connectedExternal === "true") {
      this.contentFormRules["externalUrl"] = [
        { required: true, message: t("v210831.please-enter-the-adress"), trigger: "blur" }
      ];
      if (this.contentForm.admissionExamination === "true") {
        this.contentFormRules["examinationCode"] = [
          { required: true, message: t("v210831.please-select-an-test"), trigger: "change" }
        ];
        this.contentFormRules["signUpDeadlineTime"] = [
          { required: true, message: t("v210831.please-enter-the-apply-end-time"), trigger: "change" }
        ];
      }
    } else {
      this.contentFormRules["learnContentText"] = [
        { required: true, message: t("v210831.please-enter-the-content"), trigger: "blur" }
      ];
      if (this.contentForm.admissionExamination === "true") {
        this.contentFormRules["examinationCode"] = [
          { required: true, message: t("v210831.please-select-an-test"), trigger: "change" }
        ];
        this.contentFormRules["signUpDeadlineTime"] = [
          { required: true, message: t("v210831.please-enter-the-apply-end-time"), trigger: "change" }
        ];
      }
    }
  }
  // 提交时同步表单数据
  changeFormData() {
    let data = JSON.parse(JSON.stringify(this.contentForm));
    // this.contentForm.examinationCode = "";
    // this.contentForm.signUpDeadlineTime = "";
    // this.contentForm.externalUrl = "";
    // this.contentForm.learnContentText = "";
    if (this.contentForm.connectedExternal === "true") {
      this.contentForm.externalUrl = data.externalUrl;
    } else {
      this.contentForm.learnContentText = data.learnContentText;
      if (this.contentForm.admissionExamination === "true") {
        this.contentForm.examinationCode = data.examinationCode;
        this.contentForm.signUpDeadlineTime = data.signUpDeadlineTime;
      }
    }
  }
  changeRangeList() {
    if (this.contentForm.scopeType === "storeType") {
      this.contentForm.scopeCodeList = this.storeType;
    } else {
      this.contentForm.scopeCodeList = this.staffLevel;
    }
    if (this.contentForm.scopeCodeList.length !== 0) {
      (this.$refs["scopeType"] as any).clearValidate();
    }
  }
  addLeafFlag(list) {
    const f = function(list) {
      list.forEach(item => {
        if (!item.childrenList || item.childrenList.length < 1) {
          delete item.childrenList;
        } else {
          f(item.childrenList);
        }
      });
    };
    f(list);
    return list;
  }
  categoryCasProps: any = {
    label: "className",
    value: "classCode",
    children: "childrenList",
    emitPath: false
  };
  // 获取分类列表
  getContentClassList(callback?) {
    this.queryLearnClassList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      let arr = this.addLeafFlag(data.data.list || []);
      this.contentClassList = arr.concat();
      this.contentClassListCopy = arr.concat();
      callback && callback();
    });
  }
  // 添加学习分类
  classSearchValue: string = ""; // 分类搜索keyword
  addNewClass() {
    (this.$refs["classSelect"] as any).blur();
    this.createLearnClass({ className: this.classSearchValue }).then(res => {
      this.$message.success(t("v210831.new-successfully"));
      this.contentForm.learnContentType = res.data;
      this.getContentClassList();
    });
  }
  handleSearchClass(val) {
    if (val) {
      this.classSearchValue = val;
      this.contentClassList = this.contentClassList.filter(item => {
        return item.label.includes(val);
      });
    } else {
      this.contentClassList = this.contentClassListCopy;
    }
  }
  async handleChangeClas(e) {
    let name = "";
    console.log("[ e ]", e);
    const res = await this.getDefaultSerialNumber({ learnContentType: e });
    this.contentForm.serialNumber = res.data;
    this.contentClassList.forEach(item => {
      if (item.value === e) {
        name = item.label;
      }
    });
    this.classSearchValue = name;
  }
  // 上传图片
  handleBannerUploadSuccess(url) {
    (this.$refs.contentForm as any).validateField("bannerUrl");
    this.getImageColor(url);
  }
  getImageColor(url) {
    console.log(url);
    const _this = this;
    let canvas = document.createElement("canvas"),
      ctxt = canvas.getContext && canvas.getContext("2d");
    var img = new Image();
    // 要求跨域处理
    img.crossOrigin = "Anonymous";
    img.src = url; //src也可以是从文件选择控件中取得。
    img.onload = () => {
      let [width, height] = getImageSize(img);
      canvas.height = height;
      canvas.width = width;
      ctxt.drawImage(img, 0, 0);
      var data = ctxt.getImageData(~~(width / 2), height - 2, 1, 1).data; //读取整张图片的像素。
      let [r, g, b] = data;
      _this.contentForm.campaignImageColor = `rgb(${[r, g, b].join()})`;
    };
  }
  // 仅保存
  handleInsertContent() {
    this.contentForm.isRelease = false;
    this.insertContentItem();
  }
  // 发布
  handleReleaseContent() {
    (this.$refs.contentForm as any).validate(valid => {
      if (valid) {
        this.dialogFormVisible = true;
      }
    });
  }
  handleInsert() {
    this.contentForm.isRelease = true;
    this.insertContentItem();
  }
  // 提交
  insertContentItem() {
    (this.$refs.contentForm as any).validate(valid => {
      if (valid) {
        // this.repeatTitle(this.contentForm).then(res => {
        //   if (res.data.repeat) {
        //     this.$message.warning("标题已存在");
        //     this.dialogFormVisible = false;
        //   } else {
        this.changeFormData();
        if (this.isAdd) {
          // 新建
          this.createLearnContent(this.contentForm).then(() => {
            let message =
              t("v210831.learning-content") +
              (this.contentForm.isRelease ? t("v210831.release") : t("base.save")) +
              t("v210831.success");
            this.$message.success(message);
            this.$router.go(-1);
          });
        } else {
          // 编辑
          this.updateLearnContent(this.contentForm).then(() => {
            this.$message.success(t("v210831.learning-content-edited"));
            this.$router.go(-1);
          });
        }
        //   }
        // });
      }
    });
  }
  // 上传文件
  handleUploadSuccess(response, file, fileList) {
    if (file.status === "success" && this.fileRelation[file.raw.type]) {
      this.contentForm.annexList.push({
        name: file.name,
        url: this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key,
        annexName: file.name,
        annexType: this.fileRelation[file.raw.type],
        annexUrl: this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key
      });
    } else {
      this.contentForm.annexList = this.contentForm.annexList.slice();
    }
  }
  beforeRemove(file, fileList) {
    this.contentForm.annexList = fileList;
  }
  handlePreview(file) {
    window.location.href = file.url;
  }
  uploadTokenObj: any = {};
  generatorBusinessCode() {
    return {
      businessType: "0",
      businessCode: getUuid(15)
    };
  }
  uploadBusinessInfo: any = { businessType: "0" };
  uploadUrl: string = "";
  fileRelation: any = {
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "application/x-excel": "xls",
    "application/vnd.ms-excel": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/pdf": "pdf",
    "video/mp4": "mp4",
    "application/mp4": "mp4"
  };
  uploadFileCb(file) {
    let fileType = "";
    if (this.fileRelation[file.type]) {
      fileType = this.fileRelation[file.type];
    } else {
      this.$message.warning(t("v210831.incorrect-file-format"));
      return;
    }
    return this.getUploadToken(this.generatorBusinessCode()).then(data => {
      this.uploadTokenObj = {
        url: data.data.host,
        key: `${data.data.dir}/${file.name}`,
        policy: data.data.policy,
        OSSAccessKeyId: data.data.accessId,
        Signature: data.data.signature,
        success_action_status: 200,
        domain: data.data.domain
      };
      this.uploadUrl = data.data.host;
    });
  }
  async beforeAvatarUpload(file) {
    await this.uploadFileCb(file);
  }
}
